.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: 40%;
        left: 50%;
        width: 60%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;

        h2 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-70;
            color: $color-white;
            text-align: center;
            text-shadow: 0 0 10px $color-black;
            padding-bottom: 50px;
        }
        p {
            font-family: $font-family-2;
            font-weight: normal;
            font-size: $font-size-20;
            color: $color-white;
            text-align: center;
            text-shadow: 0 0 10px $color-black;
            margin: 0;
            line-height: 1.3
        }
        @media screen and (max-width: 1750px) {
           top: 37%;
            @media screen and (max-width: 1650px) {
                top: 35%;
                width: 64%;
                @media screen and (max-width: 1545px) {
                    top: 35%;
                    width: 72%;
                    @media screen and (max-width: 1475px) {
                        h2 {
                            font-size: 3rem;
                        }
                        @media screen and (max-width: 1185px) {
                            top: 37%;
                            width: 85%;
                            p {
                                font-size: 0.9rem;
                                br {
                                    display: none;
                                }
                            }
                            @media screen and (max-width: $size-sm-max) {
                                h2 {
                                    font-size: 2.5rem;
                                    padding-bottom: 25px;
                                }
                                @media screen and (max-width: $size-xs-max) {
                                    width: 90%;
                                    @media screen and (max-width: 390px) {
                                        h2 {
                                            font-size: 2.15rem;
                                        }
                                        @media screen and (max-width: 360px) {
                                            top: 43%;
                                            h2 {
                                                font-size: 2rem;
                                                padding-bottom: 15px;
                                            }
                                            p {
                                                font-size: 0.85rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fb-arrow {
        padding-top: 35px;
        position: absolute;
        right: 6.9%;
        bottom: 50px;
        z-index: 10;
        a.fb {
            position: relative;
            img {
                transition: 0.3s;
                &:nth-child(1) {
                    opacity: 1;
                }
                &:nth-child(2) {
                    position: absolute;
                    opacity: 0;
                    left: 0;
                }
            }
            &:hover {
                img {
                    transition: 0.3s;
                    &:nth-child(1) {
                        opacity: 0;
                    }
                    &:nth-child(2) {
                        opacity: 1;
                    }
                }
            }
        }
        a.arrow {
            position: relative;
            top: 25px;
        }
        @media screen and (max-width: $size-md-max) {
            display: none;
        }
    }

    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
              //  height: calc(100vw / 480 * 880);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
                &.mobile {
                    display: block;
                }
                &.ecran {
                    display: none;
                }
        	}
    }
}
