// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

p {
    font-family: $font-family-2;
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text-image {
    padding: 25px 0 0 0;
    position: relative;
    .left-side {
        width: 45%;
        .mini-menu {
            justify-content: right;
            padding-right: 50px;
            a.link {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-12;
                color: #899596;
                text-transform: uppercase;
                transition: 0.3s;
                &:hover {
                    color: #DEAC5F;
                    transition: 0.3s;
                }
            }
        }
        .contenu {
            background: #DEAC5F;
            position: absolute;
            width: 55%;
            bottom: 0;
            top: 20%;
            padding: 100px 7% 0 7%;
            h3 {
                font-family: $font-family-1;
                font-weight: normal;
                font-size: $font-size-70;
                color: #124248;
                text-transform: initial;
                padding-bottom: 20px;
            }
            p {
                font-weight: 500;
                font-size: $font-size-18;
                color: $color-black;
                margin: 0;
                line-height: 1.3;
            }
            .section-arrow {
                background: #124248;
                width: 244px;
                height: 250px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
    .right-side {
        width: 55%;
        margin-left: auto;
    }
    @media screen and (max-width: 1655px){
        .left-side {
            .contenu {
                .section-arrow {
                    width: 170px;
                    height: 195px;
                    .img-responsive {
                        width: 14%;
                    }
                }
            }
        }
        @media screen and (max-width: 1550px){
            .left-side {
                .contenu {
                    padding: 65px 0 0 4%;
                    p {
                        width: 77%;
                        br {
                            display: none;
                        }
                    } 
                }
            }
            @media screen and (max-width: 1460px){
                .left-side {
                    .contenu {
                        h3 {
                            font-size: 3rem;
                        }
                    }
                }
                @media screen and (max-width: 1350px){
                    .left-side {
                        .contenu {
                            padding: 65px 7% 0 7%;
                            p br {
                                display: none;
                            }
                        }
                    }
                    @media screen and (max-width: 1210px){
                        flex-direction: column;
                        .left-side {
                            width: 100%;
                            .contenu {
                                position: relative;
                                padding: 75px 5% 250px 5%;
                                width: 100%;
                            }
                            .mini-menu {
                                justify-content: initial;
                                padding: 0 0 25px 5%;
                            }
                        }
                        .right-side {
                            width: 100%;
                        }
                        @media screen and (max-width: $size-sm-max){
                            .left-side {
                                .contenu {
                                    p {
                                        width: 100%;
                                    }
                                }
                            }

                            @media screen and (max-width: $size-xs-max){
                                .left-side {
                                    .contenu {
                                        h3 {
                                            font-size: 2.7rem;
                                            br {
                                                display: none;
                                            }
                                        }
                                        .section-arrow {
                                            width: 135px;
                                            height: 175px;
                                            .img-responsive {
                                                width: 15%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-images {
    .title {
        padding: 100px 5%;
        h3 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-80;
            color: $color-black;
            text-transform: initial;
        }
    }
    .section-images.accordion {
        font-size: 0;
        height: 100%;
        .image.accordion__item {
            font-size: 1rem;
            display: inline-block;
            vertical-align: top;
            height: 100%;
            min-height: 720px;
            box-sizing: border-box;
            position: relative;
            outline: none;
            background-size: cover;
            // border: 1px solid black;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 720px;
            &:nth-child(1) {
                background-image: url("../images/slide_01.jpg");
            }
            &:nth-child(2) {
                background-image: url("../images/slide_02.jpg");
            }
            &:nth-child(3) {
                background-image: url("../images/slide_03.jpg");
            }
            &:nth-child(4) {
                background-image: url("../images/slide_04.jpg");
            }


            div.txt-box {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50% , -50%);
              div.wrapper {
                position: relative;
              }
              h3 {
                color: $color-white;
                font-size: 40px;
                transition: .5s;
                text-align: center;
                position: absolute;
                top: 0;

                &.overlay {
                  opacity: 0;
                  visibility: hidden;
                  transition: .5s;
                }
              }
            }
            &.active {
              div.txt-box {
                h3 {
                  opacity: 0;
                  visibility: hidden;
                  transition: .5s;
                  &.overlay {
                    transition: .5s;
                    opacity: 1;
                    transform: translate(-50% , 0);
                    visibility: visible;;
                  }
                }
              }
            }
        }
    }
}

#section-services {
    padding: 100px 5%;
    h3 {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-70;
        color: #124248;
        text-transform: uppercase;
    }
    .section-bloc {
        padding-top: 50px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px;
        //align-items: start;
        //justify-items: center;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(3, 1fr);
            @media screen and (max-width: $size-sm-max){
                grid-template-columns: repeat(2, 1fr);
                @media screen and (max-width: $size-xs-max){
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
        .bloc {
            padding: 35px;
            height: 15vw;
            min-height: 350px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .icon {
                padding-bottom: 50px;
                .img-responsive {
                    margin: auto;
                }
            }
            .text {
                h4 {
                    font-family: $font-family-2;
                    font-size: $font-size-36;
                    font-weight: normal;
                    text-align: center;
                }
            }
            &.green {
                background: #124248;
                h4 {
                    color: #E1B46E;
                }
            }
            &.gold {
                background: #E1B46E;
            }
        }
    }
    .text {
        padding-top: 35px;
        h4 {
            font-family: $font-family-2;
            font-weight: normal;
            font-size: $font-size-30;
            color: #124248;
        }
    }
    @media screen and (max-width: $size-xs-max){
        h3 br {
            display: none;
        }
    }
}

/*******************************************************************************
* ÉVALUATIONS
*******************************************************************************/

#section-header {
    padding: 50px 7% 200px 7%;
    position: relative;
    .left-side {
        width: 30%;
        h2 {
            font-weight: normal;
            font-size: $font-size-32;
            color: #124248;
        }
        .bloc-texte {
            background: #fff;
            position: absolute;
            padding: 60px 50px 60px 0;
            z-index: 2;
            width: 36%;
            top: 25%;
            p {
                font-family: $font-family-3;
                font-weight: normal;
                font-size: $font-size-18;
                color: #899596;
                line-height: 1.5;
                margin: 0;
                span {
                    color: #124248;
                }
            }
        }
    }
    .right-side {
        width: 100%;
        margin-right: 8%;
        z-index: 1;
    }
    .solo-img {
        position: absolute;
        right: 0;
        bottom: 4%;
        margin-right: 7%;
    }
    &::before {
        position: absolute;
        width: 86%;
        height: 450px;
        content: '';
        background: #DEAC5F21;
        bottom: 0;
        z-index: -1;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        padding: 50px 5% 0 5%;
        margin-bottom: 25px;
        .left-side {
            width: 100%;
            .bloc-texte {
                position: initial;
                padding: 50px 0;
                width: 100%;
            }
        }
        .right-side {
            margin-right: 0;
        }
        .solo-img {
            position: initial;
            margin-right: 0;
            display: flex;
            justify-content: right;
            margin-top: 5px;
        }
        &::before {
            bottom: -2%;
            height: 500px;
            width: 70%;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 25px 5% 0 5%;
            margin-bottom: 0;
            &::before {
                display: none;
            }
        }
    }
}

#section-burst {
    padding: 50px 7%;
    a.link {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-12;
        color: #899596;
        text-transform: uppercase;
        transition: 0.3s;
        &:hover {
            color: #DEAC5F;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 50px 5%;
        @media screen and (max-width: $size-xs-max) {
            a.link {
                font-size: 0.65rem;
            }
        }
    }
}

#section-img-text {
    position: relative;
    .left-side {
        width: 65%;
    }
    .right-side {
        width: 35%;
        background: #EDEDED;
        padding: 75px 50px;
        position: absolute;
        right: 0;
        margin-right: 7%;
        h4 {
            font-weight: normal;
            font-size: $font-size-32;
            color: #124248;
        }
        p {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-18;
            color: #899596;
            line-height: 1.5;
            margin: 0;
            padding: 10px 0 35px 0;
        }
        a.btn-evaluation {
            background: #124248;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 230px;
            height: 75px;
            border-radius: 10px;
            border: 1px solid #DEAC5F;
            margin-top: 10px;
            transition: 0.3s;
            h5 {
                font-weight: normal;
                font-size: $font-size-18;
                color: #DEAC5F;
                text-transform: uppercase;
            }
            &:hover {
                background: #DEAC5F;
                border: 1px solid #124248;
                transition: 0.3s;
                h5 {
                    color: #124248;
                }
            }
        }
    }
    @media screen and (max-width: 1550px) {
        .right-side {
            width: 40%;
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .left-side {
                width: 100%;
            }
            .right-side {
                width: 100%;
                position: initial;
                margin-right: 0;
                padding: 75px 5%;
            }
        }
    }
}

#section-background {
    padding: 75px 7%;
    background: #124248;
    margin: 50px 0;
    .left-side {
        width: 70%;
        margin-right: 35px;
    }
    .right-side {
        width: 30%;
        h3 {
            font-weight: normal;
            font-size: $font-size-32;
            color: #DEAC5F;
        }
        p {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-18;
            color: #899596;
            line-height: 1.5;
            margin: 0;
            padding: 10px 0 35px 0;
        }
        a.btn-evaluation {
            background: #124248;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 230px;
            height: 75px;
            border-radius: 10px;
            border: 1px solid #DEAC5F;
            margin-top: 10px;
            transition: 0.3s;
            h5 {
                font-weight: normal;
                font-size: $font-size-18;
                color: #DEAC5F;
                text-transform: uppercase;
            }
            &:hover {
                background: #DEAC5F;
                border: 1px solid #124248;
                transition: 0.3s;
                h5 {
                    color: #124248;
                }
            }
        }
    }
    @media screen and (max-width: 1465px) {
        .right-side {
            p br {
                display: none;
            }
        }
        @media screen and (max-width: 1200px) {
            margin: 0;
            padding: 75px 5%;
            @media screen and (max-width: 1100px) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin-right: 0;
                    padding-bottom: 35px;
                }
                .right-side {
                    width: 100%;
                }
            }
        }
    }
}

#section-text-img {
    padding: 0 0 0 7%;
    .left-side {
        background: #DEAC5F1C;
        width: 40%;
        padding: 0 7%;
        h3 {
            font-weight: normal;
            font-size: $font-size-32;
            color: #124248;
        }
        p {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-18;
            color: #899596;
            line-height: 1.5;
            margin: 0;
            padding: 10px 0 15px 0;
        }
        a.btn-evaluation {
            background: #124248;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 230px;
            height: 75px;
            border-radius: 10px;
            border: 1px solid #DEAC5F;
            margin-top: 10px;
            transition: 0.3s;
            h5 {
                font-weight: normal;
                font-size: $font-size-18;
                color: #DEAC5F;
                text-transform: uppercase;
            }
            &:hover {
                background: #DEAC5F;
                border: 1px solid #124248;
                transition: 0.3s;
                h5 {
                    color: #124248;
                }
            }
        }
    }
    .right-side {
        width: 60%;
    }
    @media screen and (max-width: $size-md-max) {
        padding: 0;
        @media screen and (max-width: 1100px) {
            flex-direction: column;
            .left-side {
                width: 100%;
                padding: 75px 5%;
            }
            .right-side {
                width: 100%;
            }
        }
    }
}

/*******************************************************************************
* AUTRES SERVICES
*******************************************************************************/









/*******************************************************************************
* NOTRE ÉQUIPE
*******************************************************************************/

#section-equipe {
    padding: 100px 7% 25px 7%;
    .section-profil {
        padding-bottom: 200px;
        position: relative;
        .left-side {
            width: 30%;
            margin-right: 5%;
        }
        .right-side {
            width: 60%;
            .titre {
                padding-bottom: 35px;
                h3 {
                    font-weight: normal;
                    font-size: $font-size-34;
                    text-transform: uppercase;
                    padding-bottom: 25px;
                    color: #124248;
                }
                h4 {
                    font-family: $font-family-3;
                    font-weight: bold;
                    font-size: $font-size-20;
                    color: #124248;
                }
            }
            .description {
                p {
                    font-family: $font-family-3;
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: #899596;
                    line-height: 1.5;
                    margin: 0;
                    padding-bottom: 25px;
                }
            }
        }
        &::before {
            content: '';
            position: absolute;
            width: 78%;
            height: 730px;
            z-index: -1;
            right: 0;
        }
        &:nth-child(odd) {
            &::before {
                background: #EDEDED74;
            }
        }
        &:nth-child(even) {
            &::before {
                background: #DEAC5F1C;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        padding: 100px 5% 25px 5%;
        .section-profil {
            .left-side {
                width: 35%;
            }
            .right-side {
                padding-right: 50px;
            }
            &::before {
                width: 75%;
                height: 780px;
            }
        }
        @media screen and (max-width: 1445px) {
            .section-profil::before {
                display: none;
            }
            @media screen and (max-width: 1200px) {
                .section-profil {
                    flex-direction: column;
                    padding-bottom: 50px;
                    .left-side {
                        width: 45%;
                        margin: 0 0 35px 0;
                    }
                    .right-side {
                        padding-right: 0;
                        width: 100%;
                        text-align: center;
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .section-profil {
                        .left-side {
                            width: 55%;
                        }
                        .right-side {
                            .titre {
                                padding-bottom: 15px;
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .section-profil {
                            .left-side {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* DEMANDE DE SOUMISSION
*******************************************************************************/

#section-formulaire {
    padding: 0 10% 50px 10%;
    @media screen and (max-width: 1445px) {
        padding: 0px 5% 50px 5%;
        @media screen and (max-width: 1180px) {
            form label.item {
                width: 50%;
            }
            @media screen and (max-width: $size-sm-max) {
                .autres {
                    flex-direction: column;
                    width: 100%;
                    label.item {
                        width: 100%;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 0 0 50px 0;
                    form label.item {
                        width: 100%;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees.mobile {
    background: #fff;
    padding: 75px 5%;
    display: none;
    .left-side {
        width: 50%;
        margin-right: 50px;
    }
    .right-side {
        width: 50%;
    }
    h4 {
        font-family: $font-family-3;
        font-weight: bold;
        font-size: $font-size-26;
        color: #124248;
    }
    p {
        font-family: $font-family-3;
        font-weight: normal;
        font-size: $font-size-16;
        line-height: 1.5;
        margin: 0;
        color: #124248;
    }
    h5 {
        font-family: $font-family-3;
        font-weight: normal;
        font-size: $font-size-26;
        color: #124248;
    }
    @media screen and (max-width: $size-md-max) {
        display: flex;
        padding: 75px 5% 0 5%;
        @media screen and (max-width: $size-xs-max) {
            flex-direction: column;
            padding: 60px 5% 0 5%;
            .left-side {
                width: 100%;
                margin: 0 0 35px 0;
            }
            .right-side {
                width: 100%;
            }
        }
    }
}

#section-heures-map {
    padding: 75px 10%;
    .left-side {
        width: 50%;
        h3 {
            font-weight: normal;
            font-size: $font-size-46;
            color: #124248;
            text-transform: initial;
            padding-bottom: 25px;
        }
        h4 {
            font-family: $font-family-3;
            font-weight: bold;
            font-size: $font-size-20;
            line-height: 2;
            color: #124248;
        }
        h5 {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-20;
            line-height: 2;
            color: #124248;
        }
        .jour {
            width: 50%;
        }
    }
    .right-side {
        width: 85%;
    }
    @media screen and (max-width: 1450px) {
        .left-side {
            margin-right: 5%;
        }
        @media screen and (max-width: $size-md-max) {
            padding: 75px 5%;
            .right-side {
                width: 50%;
            }
            @media screen and (max-width: $size-sm-max) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 35px 0;
                }
                .right-side {
                    width: 100%;
                }
            }
        }
    }
}

#content {
    h3#firstHeading, p a {
        color: #124248;
    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {


}

@media screen and (max-width: $size-sm-max) {


}

@media screen and (max-width: $size-xs-max) {


}
