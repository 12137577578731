$input_height: 50px;
$input_back_color: $bg-color-body;
$input_text_color: $text-color;
$input_text_size: $font-size-20;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: $font-size-18;

$input_label_color: $text-muted;
$input_label_size: $font-size-18;

$input_border_color: $color-primary;
$input_border_radius: $round-corner-radius; //based on variable
$input_border_size: 1px;
$input_border_style: solid;

$label_checkbox_border_color: $text-muted;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: $color-error;
$message_errors_border_color: $color-error;
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: $color-success;
$message_success_border_color: lighten($color-success, 20%);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: $bg-color-body;

form {
    input.form-control, textarea.form-control {
        height: 125px;
        color: #124248;
        font-size: $input_text_size;
        background-color: #1242482B;
        border: none;
        border-radius: $input_border_radius;
    }
    .autres {
        label.item {
            padding-left: 0;
            padding-bottom: 0;
        }
        input.form-control.autres {
            height: 45px;
        }
    }
    input::placeholder, textarea::placeholder {
        font-weight: bold;
        font-size: $font-size-26;
        color: #124248 !important;
    }
    label {
        color: $input_label_color;
        font-size: $input_label_size;
    }
    label.title-list {
        font-weight: bold;
        font-size: $font-size-28;
        color: #124248;
        text-transform: uppercase;
        padding-bottom: 25px;
    }
    label.item.full {
        width: 100% !important;
    }
    label.item {
        display: block;
        position: relative;
        padding-left: 55px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-transform: inherit;
        padding-bottom: 20px;
        font-family: $font-family-3;
        font-weight: bold;
        font-size: $font-size-24;
        text-transform: uppercase;
        color: #124248;
        width: 33%;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }
    .checkmark {
        position: absolute;
        top: -3px;
        left: 0;
        height: 30px;
        width: 30px;
        background-color: #fff;
        border: 2px solid #124248;
        border-radius: 5px;
    }

    /* When the checkbox is checked, add a blue background */
    .item input:checked ~ .checkmark {
        background-color: #124248;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .item input:checked ~ .checkmark:after {
        display: block;
        background-color: #1242482B;
    }

    .title {
        text-transform: uppercase;
        color: $input_text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .clear-both {
        clear: both;
    }
    .radios, .checkboxes {
        width: 100%;
        margin: 20px 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input[type="radio"], input[type="checkbox"] {
            position: absolute;
            visibility: hidden;
            &:checked+label:before {
                background-color: $input_checked_color;
            }
        }
        input[type="radio"] {
            &+label:before {
                border-radius: 100%;
            }
        }
        label {
            display: inline-block;
            cursor: pointer;
            color: $input_label_color;
            position: relative;
            padding: 6px 5px 6px 35px;
            font-size: $input_text_size;
            transition: all .3s ease;
            &:before {
                content: "";
                display: inline-block;
                width: $input_text_size;
                height: $input_text_size;
                position: absolute;
                left: 0px;
                background: none;
                border: 2px solid $label_checkbox_border_color;
            }
        }
    }
    .radios {
        justify-content: space-between;
    }
    textarea {
        //margin: 2em 0;
        height: auto !important;
    }
    .buttons {
        display: flex;
        justify-content: right;
        margin: 0;
    }

}
.form-group.info {
    margin-bottom: -15px;
}
.has-error {
    .form-control {
        color: $message_errors_text_color;
    }
}
.help-block {
    min-height: 15px;
}
.with-errors {
    li {
        line-height: 1;
    }
}



.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: $font-size-20;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}
