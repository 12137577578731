footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 0 5%;
        @extend .items-center;
        @extend .text-color-grey;
        @extend .font-footer;

        .logo {
            padding-bottom: 40px;
        }
        .footer-text {
            .left-side {
                padding-right: 12%;
                .copyright {
                    font-family: $font-family-2;
                    font-weight: normal;
                    font-size: $font-size-14;
                    color: #909090;
                    text-transform: uppercase;
                }
            }
            .center-side {
                width: 55%;
                a.link {
                    font-family: $font-family-2;
                    font-weight: normal;
                    font-size: $font-size-14;
                    color: #899596;
                    text-transform: uppercase;
                    transition: 0.3s;
                    &:hover {
                        color: #E1B46E;
                        transition: 0.3s;
                    }
                }
            }
            .right-side {
                a {
                    position: relative;
                    img {
                        transition: 0.3s;
                        &:nth-child(1) {
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            position: absolute;
                            opacity: 0;
                            left: 0;
                        }
                    }
                    &:hover {
                        img {
                            transition: 0.3s;
                            &:nth-child(1) {
                                opacity: 0;
                            }
                            &:nth-child(2) {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1795px){
            .footer-text {
                .left-side {
                    padding-right: 10%;
                }
            }
            @media screen and (max-width: 1695px){
                .footer-text {
                    .left-side {
                        padding-right: 7%;
                    }
                }
                @media screen and (max-width: 1555px){
                    .logo a .img-responsive {
                        margin: auto;
                    }
                    .footer-text {
                        flex-direction: column-reverse;
                        .center-side {
                            width: 100%;
                            text-align: center;
                            padding: 20px 0;
                        }
                    }
                    @media screen and (max-width: $size-xs-max){
                        .footer-text .left-side .copyright {
                            text-align: center;
                            line-height: 1.3;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1555px){
        min-height: 225px;
    }
}
