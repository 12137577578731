.btn {
    background-color: #124248;
    color: #DEAC5F;
    border: 1px solid #DEAC5F;
    border-radius: 10px;
    padding: 25px;
    font-weight: normal;
    font-size: $font-size-18;
    width: 100%;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        background-color: #DEAC5F;
        color: #124248;
    }
}
